<template>
  <div>
    <list :key="$store.state.organisations.organisationMemberKey" :editRole="editRole" />
    <add v-if="!edit" />
    <edit v-if="edit" :edit="edit" :key="edit.username" :callback="setSuccess" />
  </div>
</template>
<script>
export default {
  components: {
    add () { return import('./add/index.vue') },
    edit () { return import('./add/edit.vue') },
    list () { return import('./list/index.vue') }
  },
  props: {},
  data () {
    return {
      edit: false
    }
  },
  watch: {
    '$store.state.organisations.organisationMemberKey' () { this.edit = false }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    editRole (data) {
      this.edit = data
    },
    setSuccess (data) {

    }
  }
}
</script>
<style lang="sass" scoped>
</style>
